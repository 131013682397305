import Button from '@material-ui/core/Button';
import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { upperFirst, truncate } from 'lodash';

const styles = theme => ({
  cardActions: {
    paddingTop: 0,
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 14,
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 16,
    },
  },
  cardContent: {
    paddingBottom: 4,
    paddingTop: 2,
    flexGrow: 1,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 4,
      paddingRight: 4,
    },
    [theme.breakpoints.up('sm')]: {
      paddingLeft: 12,
      paddingRight: 12,
    },
  }
});

class ProductCard extends Component {
  logClick = () => {
    window.dataLayer.push({
      'eventAction': this.props.product.product_link,
      'eventCategory': `productClick||${this.props.product.merchant_name}`,
      'eventLabel': this.props.product.title,

      'merchant': this.props.product.merchant_name,
      'productBrand': this.props.product.brand,
      'productName': this.props.product.title,
      'productLink': this.props.product.product_link,
      'event': 'productClick',
    })
  }

  renderPrices = () => {
    if (this.props.product.before_sale) {
      return <div>
        <Typography style={{ textDecoration: 'line-through' }} variant='caption'>
          {this.props.product.before_sale}€
        </Typography>
        <Typography style={{ fontSize: '1.5em', fontWeight: 600 }} variant='title'>
          {this.props.product.price}€
        </Typography>
      </div>
    }
    else {
      return <Typography style={{ fontSize: '1.5em', fontWeight: 600 }} variant='title'>
        {this.props.product.price}€
      </Typography>
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <Card style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', flexBasis: '100%' }}>
        <div style={{ width: '100%', paddingTop: '100%', position: 'relative' }}>
          <CardMedia
            style={{ position: 'absolute', maxHeight: '100%', objectFit:'contain', top: 0, left: 0, bottom: 0, right: 0 }}
            src={this.props.product.image_link}
            title='product'
            component='img'/>
        </div>
        <CardContent className={classes.cardContent}>
          <Typography variant='title' style={{ color: '#151F2E', fontSize: '1rem' }}>
            {truncate(this.props.product.title, { length: 110 })}
          </Typography>
          <Typography variant='body2'>{this.props.product.brand}</Typography>
          <Typography variant='body2' style={{ color: '#6d6d6b' }}>
            Par {upperFirst(this.props.product.merchant_name)}
          </Typography>
        </CardContent>
        <CardActions
          className={classes.cardActions}
          disableActionSpacing>
          {this.renderPrices()}
          <Button
            style={{
              padding: '2px 8px',
              minHeight: 25,
              textTransform: 'initial',
              backgroundColor: '#E14C2E',
              fontSize: '1.3em',
              alignSelf: 'flex-end' }}
            href={this.props.product.product_link}
            onClick={this.logClick}
            target='_blank'
            color='primary'
            size='small'
            variant='contained'>
            Voir l{'\''}offre
          </Button>
        </CardActions>
      </Card>
    );
  }
}

ProductCard.propTypes = {
  product: PropTypes.shape({
    image_link: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    brand: PropTypes.string.isRequired,
    merchant_name: PropTypes.string.isRequired,
    product_link: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    before_sale: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  }),
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductCard);
