import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

import Filter from './components/Filter';
import RangeFilter from './components/RangeFilter';
import Sort from './components/Sort';


class FilterBar extends Component {
    render() {
        return (
            <Grid container direction='column'>
                <Sort handleChangeSort={this.props.handleChangeSort}
                      sortingFilter={this.props.sortingFilter}/>
                <Divider style={{ marginBottom: 5, width: '90%' }}/>
                <Filter attribute='merchant_name' operator='or' title='Marchands'/>
                <Divider style={{ marginBottom: 5, width: '90%' }}/>
                <Filter attribute='brand' operator='or' title='Marque'/>
                <Divider style={{ marginBottom: 5, width: '90%' }}/>
                <RangeFilter attribute='price' title='Prix' range_unit='€'/>
            </Grid>
        );
    }
}

FilterBar.propTypes = {
    handleChangeSort: PropTypes.func.isRequired,
    sortingFilter: PropTypes.string.isRequired
};
export default FilterBar;
