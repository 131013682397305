import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from 'react-router-dom/Link';
import Button from '@material-ui/core/Button';

import logo from 'ressources/icons/logo-big.png';

class About extends Component {
  render() {
    return (
      <div>
        <Grid container spacing={8}>
          <Button component={Link} to='' style={{ padding: 0, minHeight: 24 }} >
            <img src={logo} height='48' alt='logo AlphaBuy'/>
          </Button>
        </Grid>
        <Grid container direction='column' style={{ marginLeft: '10vw', marginRight: '10vw', width: '80vw' }}>
          <Typography variant='display2'>
            A propos
          </Typography>
          <Typography variant='body1'>
            AlphaBuy est un nouveau comparateur de prix indépendant qui vous permet de facilement comparer les prix des produits que nous référençons. AlphaBuy enrichi chaque jour le portefeuille des marchands référencés pour vous donner toujours plus de choix et simplifier ainsi votre expérience shopping en ligne.
            AlphaBuy fait partie de la société Arcane.
          </Typography>
        </Grid>
      </div>

    );
  }
}

export default About;
