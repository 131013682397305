import React, { Component } from 'react';
import { connectStateResults } from 'react-instantsearch-dom';
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';

import ProductCard from './components/ProductCard'

const styles = theme => ({
  cardSize: {
    [theme.breakpoints.up('lg')]: {
      flexBasis: '20%',
    },
  }
})

class ProductCards extends Component {
  getHitsFiltered = (hits) => {
    return hits.filter(product =>
      this.props.merchantFilter.includes(product.merchant_name) || isEmpty(this.props.merchantFilter)
    )
  }
  render() {
    let searchResults = this.props.searchResults
    if (!searchResults)
      searchResults = {
        hits: [],
      }
    const { classes } = this.props;
    return (
      <Grid container spacing={16} alignItems='stretch'>
        {this.getHitsFiltered(searchResults.hits).map(product =>
          <Grid key={product.objectID} className={classes.cardSize} item xs={6} sm={4} md={3} xl={2} container>
            <ProductCard product={product} />
          </Grid>)}
      </Grid>

    );
  }
}

ProductCards.propTypes = {
  merchantFilter: PropTypes.array.isRequired,
  searchResults: PropTypes.shape({
    hits: PropTypes.array.isRequired,
  }),
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(connectStateResults(ProductCards));
