import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Link from 'react-router-dom/Link';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';

import logo from 'ressources/icons/logo-big.png';
import logoArcane from 'ressources/icons/logo-arcane.png';

const styles = theme => ({
  searchBar: {
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-end',
    }
  },
  logo: {
    width: 150,
    justifyContent: 'flex-start',
  },
  links: {
    [theme.breakpoints.down('xs')]: {
      width: 'auto',
    },
    [theme.breakpoints.up('sm')]: {
      width: 'calc(100% - 150px)',
    },
  },
});

class Footer extends Component {
  render() {
    const { classes } = this.props;
    return (
      <Grid container alignItems='center' style={{ backgroundColor: '#f3f3f3', padding: 8 }} >
        <Grid container className={classes.logo} item>
          <Grid item>
            <Button component={Link} to='' style={{ padding: 0, minHeight: 24 }} >
              <img src={logo} height='20' alt='logo AlphaBuy'/>
            </Button>
          </Grid>
          <Grid item container alignItems='center'>
            <Typography variant='body2' style={{ color: '#151F2E', fontSize: '0.575rem', marginTop: -2 }}>
              Powered by
            </Typography>
            <Button component='a' href='http://www.arcane.run/' style={{ padding: 0, minHeight: 16 }} >
              <img src={logoArcane} height='16' alt='logo Adscale'/>
            </Button>
          </Grid>
        </Grid>
        <Typography variant='body2' className={classes.links} style={{ color: '#151F2E' }}>
          <Grid container item style={{ justifyContent: 'center' }} spacing={8}>
              <Grid item>
                <Link to='/a-propos' style={{ textDecoration: 'none', color: '#151F2E' }}>A propos </Link>
              </Grid>
              <Grid item>●</Grid>
              <Grid item>
                <Link to='/mentions-legales' style={{ textDecoration: 'none', color: '#151F2E' }}>
                  Mentions légales
                </Link>
              </Grid>
              <Grid item>●</Grid>
              <Grid item>
                <Link to='/donnees-personnelles' style={{ textDecoration: 'none', color: '#151F2E' }}>
                Données personnelles
              </Link>
              </Grid>
              <Grid item>●</Grid>
              <Grid item>
                <a
                  href='http://www.arcane.run/'
                  target='_blank'
                  rel='noopener noreferrer'
                  style={{ textDecoration: 'none', color: '#151F2E' }}>
                  Découvrir Arcane
                </a>
              </Grid>
          </Grid>
        </Typography>
      </Grid>
    );
  }
}

Footer.propTypes = {
  classes: PropTypes.shape({
    logo: PropTypes.string.isRequired,
  }),
};


export default withStyles(styles)(Footer);
