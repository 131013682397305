import React, { Component } from 'react';
import Favicon from 'react-favicon';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import logo from './ressources/icons/logo-small.gif';
import Search from './scenes/Search/Search'
import LegalMentions from './scenes/LegalMentions/LegalMentions'
import About from './scenes/About/About'
import PersonalData from './scenes/PersonalData/PersonalData'
import Footer from './components/Footer/Footer'

const styles = theme => ({
  mainHeight: {
    margin: '1vh 1vw',
    [theme.breakpoints.down('xs')]: {
      minHeight: 'calc(99vh - 112px)',
    },
    [theme.breakpoints.up('sm')]: {
      minHeight: 'calc(99vh - 56px)',
    }
  },
});

class App extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div>
        <Favicon url={logo}/>
        <Router>
          <div>
            <main className={classes.mainHeight}>
              <Switch>
                <Route exact path='/' component={Search}/>
                <Route exact path='/mentions-legales' component={LegalMentions} />
                <Route exact path='/a-propos' component={About} />
                <Route exact path='/donnees-personnelles' component={PersonalData} />
              </Switch>
            </main>
            <Footer />
          </div>
        </Router>
      </div>
    );
  }
}

App.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
  classes: PropTypes.shape({
    mainHeight: PropTypes.string.isRequired,
  }),
};

export default withStyles(styles)(App);
