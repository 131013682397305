import React from 'react';
import ReactDOM from 'react-dom';

import './ressources/index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import TagManager from 'react-gtm-module';

TagManager.initialize({
  gtmId: 'GTM-MMW954M',
  dataLayer: { userProject: 'alphabuy' }
})

ReactDOM.render(<App />,
  document.getElementById('root'));
registerServiceWorker();
