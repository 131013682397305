import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem';


class Sort extends Component {
    state = {
        isOpen: false,
      };

      handleClose = () => {
        this.setState({ isOpen: false });
      };

      handleOpen = () => {
        this.setState({ isOpen: true });
      };

    render() {
        return (
            <div>
                <Typography variant='title' style={{ marginBottom: 5, width: '100%' }}>Trier par</Typography>
                <div style={{ padding: 5, textAlign: 'left', marginBottom: 5 }}>
                    <FormControl style={{  marginBottom: 5, width: '80%', marginRight: 15  }}>
                        <Select
                            open={this.state.isOpen}
                            onClose={this.handleClose}
                            onOpen={this.handleOpen}
                            value={this.props.sortingFilter}
                            onChange={this.props.handleChangeSort}
                            inputProps={{ name: 'sortingFilter' }} >
                            <MenuItem value={'Pertinence'}>Pertinence</MenuItem>
                            <MenuItem value={'Prix Croissant'}>Prix Croissant</MenuItem>
                            <MenuItem value={'Prix Décroissant'}>Prix Décroissant</MenuItem>
                        </Select>
                    </FormControl>
                </div>
            </div>
        );
    }
}

Sort.propTypes = {
    handleChangeSort: PropTypes.func.isRequired,
    sortingFilter: PropTypes.string.isRequired
};

export default Sort;
