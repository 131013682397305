import React, { Component } from 'react';
import { InstantSearch, Pagination } from 'react-instantsearch-dom';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import qs from 'qs';
import { cloneDeep, pull } from 'lodash'

import AppBar from './components/AppBar/AppBar'
import FilterBar from './components/FilterBar/FilterBar'
import ProductCards from './components/ProductCards/ProductCards'
import './Search.css'

const createURL = state => {
  let newUrlState = cloneDeep(state);
  return`?${qs.stringify(newUrlState)}`;
}

const searchStateToUrl = (props, searchState) =>
  searchState ? `${props.location.pathname}${createURL(searchState)}` : '';
const urlToSearchState = location => {
  let parsedQuery = qs.parse(location.search.slice(1))
  return parsedQuery;
}

class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchState: urlToSearchState(props.location),
      merchantFilter: [],
      sortingFilter: 'Pertinence'
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      this.setState({ searchState: urlToSearchState(this.props.location) });
    }
  }

  handleMerchantFilterChange = ({ target: { value: merchant_name } }) => {
    if (this.state.merchantFilter.includes(merchant_name))
      this.setState({ merchantFilter: pull(this.state.merchantFilter, merchant_name) })
    else {
      this.setState({ merchantFilter: [...this.state.merchantFilter, merchant_name] })
    }
  }

  onSearchStateChange = searchState => {
    this.props.history.push(
      searchStateToUrl(this.props, searchState),
      searchState
    );
    this.setState({ searchState });
  };

  handleChangeSort = e => {
    this.setState({ [e.target.name]: e.target.value });
  }

  getIndexName = () => {
    switch (this.state.sortingFilter) {
      case 'Prix Croissant':
        return 'ALPHABUY_products_price_asc'
      case 'Prix Décroissant':
        return 'ALPHABUY_products_price_desc'
      case 'Pertinence':
        return 'dev_ALPHABUY';
        default:
            return 'dev_ALPHABUY'
    }
  };

  render() {
    return (
      <InstantSearch
        appId='NEUIJYJXZC'
        apiKey='99d363ea8b04b92237ea27eafb8a1107'
        indexName={this.getIndexName()}
        searchState={this.state.searchState}
        onSearchStateChange={this.onSearchStateChange}
      >
        <AppBar />
        <Grid container style={{ marginTop: '2vh', marginBottom: '2vh' }}>
          <Grid item xs={12} sm={3} md={2} xl={1} style={{ marginBottom: '2vh' }}>
            <FilterBar handleChangeSort={this.handleChangeSort} sortingFilter={this.state.sortingFilter} />
          </Grid>
          <Grid item xs={12} sm={9} md={10} xl={11}>
            <ProductCards merchantFilter={this.state.merchantFilter} />
          </Grid>
        </Grid>
        <Pagination />
      </InstantSearch>
    );
  }
}

Search.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
  location: PropTypes.object.isRequired,
};

export default Search;
