import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from 'react-router-dom/Link';
import Button from '@material-ui/core/Button';

import logo from 'ressources/icons/logo-big.png';

class PersonalData extends Component {
  render() {
    return (
      <div>
        <Grid container spacing={8}>
          <Button component={Link} to='' style={{ padding: 0, minHeight: 24 }} >
            <img src={logo} height='48' alt='logo AlphaBuy'/>
          </Button>
        </Grid>
        <Grid container direction='column' style={{ marginLeft: '10vw', marginRight: '10vw', width: '80vw' }}>
          <Typography variant='display2' style={{ fontSize: '2rem' }}>
            POLITIQUE DE PROTECTION DES DONNÉES PERSONNELLES
          </Typography>
          <Typography variant='body1'>
            Nous accordons une grande importance au respect de votre vie privée. La protection de vos données personnelles est primordiale à nos yeux. C’est pourquoi, nous mettons à votre disposition notre Politique de protection des données afin de vous informer de la façon la plus claire possible des traitements mis en œuvre dans le cadre de l’utilisation des services du site.
            La présente Politique s’applique ainsi à l’ensemble des services proposées sur le site Internet https://www.alpha-buy.com
            Cette politique est soumise à la loi n° 78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés modifiée en 2004 (ci-après « loi Informatique et Libertés ») ainsi qu’au Règlement européen relatif à la protection des données personnelles du 27 avril 2016.
          </Typography>
          <Typography variant='headline'>
            Définitions
          </Typography>
          <Typography variant='body1'>
            « Responsable de traitement » Désigne la personne qui détermine les finalités et les moyens du traitement de données à caractère personnel.
            « Données à caractère personnel » (ci-après « données personnelles ») Information qui permet de vous reconnaître en tant que personne. Votre nom de famille est une donnée personnelle. Votre numéro de téléphone, votre email, votre adresse IP sont des données personnelles.
            « Traitement de données à caractère personnel » Opération ou ensemble d’opérations appliquées à des données personnelles (collecte, enregistrement, conservation, consultation etc.)
          </Typography>
          <Typography variant='headline'>
            Collecte des données personnelles
          </Typography>
          <Typography variant='body1'>
            Arcane, dont le siège est situé au 22 rue Chapon, 75003 Paris, est responsable de l’ensemble des traitements de données à caractère personnel effectuées sur le site www.alpha-buy.com
          </Typography>
          <br/>
          <br/>
          <Typography variant='body1'>
            Finalités de la collecte de vos données personnelles
            <br/>
            Arcane s’engage à collecter, traiter ou conserver vos données personnelles pour des finalités déterminées, légitimes et pertinentes.
            Vos données sont possiblement traitées pour les finalités suivantes :
            La suppression de vos données personnelles
            La mesure et l’analyse des statistiques de fréquentation du site
            Le ciblage publicitaire
            le partage des informations à nos partenaires

            Arcane considère que l’ensemble des traitements ci-dessus sont nécessaire à l’exécution du contrat passé entre un Utilisateur et Arcane
            Aussi, nous sommes susceptibles d’utiliser et de compiler les données à des fins de profilage. Le profilage correspond au traitement automatisé de vos données personnelles. Ces données sont utilisées pour évaluer, analyser et prédire vos préférences, vos intérêts et votre comportement. Nous utilisons ces données pour : par exemple, vous fournir des informations personnalisées sur les offres susceptibles de vous intéresser.
            <br/>
            <br/>

            Vos droits et comment les exercer auprès d’Arcane
            <br/>
            Conformément à la loi Informatique et Libertés du 6 janvier 1978, vous disposez d’un droit d’accès, de rectification et d’opposition des données vous concernant. Vous pouvez exercer ces droits par e-mail à l’adresse suivante : contact@arcane.run. Arcane vous adressera une réponse dans les meilleurs délais.
            A compter du 25 mai 2018 et en application du règlement 2016/679 du 27 avril 2016, vous pourrez également exercer votre droit à la limitation du traitement, à l’effacement de vos données, à la portabilité des données et à ne pas faire l’objet d’une décision individuelle automatisée, y compris le profilage.
            Dans un souci de confidentialité et de protection des données personnelles, une copie d’un titre d’identité signé devra être intégrée à la demande.
            En cas de réponse insatisfaisante, vous avez la possibilité de saisir la CNIL.
            <br/>
            <br/>
            Durée de conservation de vos données
            <br/>
            Arcane a déterminé des règles précises concernant la durée de conservation des données à caractère personnel des utilisateurs. Arcane s’engage à détruire toutes les données à caractère personnel au bout de 3 ans. Nos cookies, permettant de vous proposer des offres correspondant à vos attentes, ont un délai d’expiration limitée à 13 mois.
            Vos données après votre décès
            Conformément à l’article 40-1 de la loi Informatique et Libertés du 6 janvier 1978, vous pouvez nous transmettre vos directives relatives à la conservation, à l’effacement et à la communication de vos données personnelles après votre décès. Ces directives peuvent être générales ou particulières. Pour nous transmettre vos directives, contactez-nous par email.
            <br/>
            <br/>
            Destinataires de vos données
            <br/>
            Vos données sont transmises à des partenaires d’Arcane qui peuvent traiter les données pour leur compte (ce sont des destinataires) ou uniquement pour le compte et selon les instructions d’Arcane (ce sont des sous-traitants).
            Le destinataire des données est :
            Arcane dont le siège social est situé au 24, rue Cardinet 75017 Paris
            Les partenaires commerciaux
            Les régies marketing et publicitaires

            Arcane peut également faire appel à des sous-traitants pour les opérations suivantes :
            Le ciblage publicitaire
            La mesure et l’analyse des performances du site
            La suppression de vos données personnelles

            <br/>
            <br/>
            Délégué à la protection des données
            <br/>
            Arcane a nommé un délégué à la protection des données en charge d’assister le responsable de traitement dans le respect du Règlement européen. Pour toutes questions relatives à la protection des données personnelles chez Arcane, nous mettons à votre disposition une adresse de contact : contact@arcane.run

            <br/>
            <br/>
            Envoie des données en dehors de l’Union Européenne
            <br/>
            Vos données personnelles peuvent être transmises pour répondre aux finalités définies ci-dessus à des sociétés situées dans des pays hors de l’Union Européenne et qui ne présentent pas un niveau adéquat de protection des données personnelles.
            Préalablement au transfert hors Union Européenne et conformément à la réglementation en vigueur, Arcane met en œuvre toutes les procédures requises pour obtenir les garanties nécessaires à la sécurisation de tels transferts.
            <br/>
            <br/>
            Protection des données
            <br/>
            Arcane prend une série de mesures pour assurer la sécurité de vos données contre la perte, l'utilisation abusive, l'accès non autorisé, la divulgation, la modification ou la destruction. En particulier :
            L’accès aux bases de données est strictement réservé aux personnes habilitées à en prendre connaissance dans le cadre de leurs missions.
            Conformément à la loi Informatique et Libertés du 6 janvier 1978 et au Règlement européen, Arcane s’est assuré que les sous-traitants s’engagent à respecter la sécurité et la confidentialité des données.
            Tous les employés chez Arcane sont sensibilisés à la protection des données personnelles au travers de formations et réunions d’équipe.

            <br/>
            <br/>
            Modification de la Politique de protection des données
            <br/>
            Arcane se réserve le droit de modifier à tout moment cette Politique de protection des données. En cas de modification substantielle telle que l’introduction d’une nouvelle finalité, Arcane vous fournira au préalable des informations au sujet de cette autre finalité. Ceci afin de s’assurer que vous disposez d'un délai raisonnable pour exercer vos droits en vertu de la loi Informatique et Libertés du 6 janvier 1978 et du Règlement européen.
            Nous vous encourageons néanmoins à consulter régulièrement la Politique afin de prendre connaissance des modalités de protection de vos informations personnelles.
            Cette Politique a été mise à jour pour la dernière fois le 2 août 2018.
          </Typography>
          <Typography variant='headline'>
            Nous contacter
          </Typography>
          <Typography variant='body1'>
            Pour toute question relative à notre Politique, vous pouvez nous contacter directement par email à l’adresse suivante : contact@arcane.run.
          </Typography>
        </Grid>
      </div>

    );
  }
}


export default PersonalData;
