import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import { connectRefinementList } from 'react-instantsearch-dom';

class Filter extends Component {
  render() {
    return (
      <Grid container direction='row'>
        <Typography variant='title' style={{ marginBottom: 10, width: '100%' }}>{this.props.title}</Typography>
        {this.props.items.map(item => {
          return <Grid container item key={item.label} alignItems='center' xs={6} sm={12}>
            <div style={{ width: 100 }}>{item.label}</div>
            <Checkbox
              checked={item.isRefined}
              style={{ color: '#E14C2E', height: 30 }}
              onClick={e => {
                e.preventDefault()
                this.props.refine(item.value)
              }} />
          </Grid>
        })}
      </Grid>
    );
  }
}

Filter.propTypes = {
  refine: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
};

export default connectRefinementList(Filter);
