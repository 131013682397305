import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from 'react-router-dom/Link';
import Button from '@material-ui/core/Button';

import logo from 'ressources/icons/logo-big.png';

class LegalMentions extends Component {
  render() {
    return (
      <div>
        <Grid container spacing={8}>
          <Button component={Link} to='' style={{ padding: 0, minHeight: 24 }} >
            <img src={logo} height='48' alt='logo AlphaBuy'/>
          </Button>
        </Grid>
        <Grid container direction='column' style={{ marginLeft: '10vw', marginRight: '10vw', width: '80vw' }}>
          <Typography variant='display2'>
            Mentions légales
          </Typography>
          <Typography variant='headline'>
            CONDITIONS GÉNÉRALES D’UTILISATION
          </Typography>
          <Typography variant='body1'>
            Les présentes Conditions Générales d’Utilisation (ci-après « Conditions Générales d’Utilisation » ou « CGU ») ont pour objet d’encadrer l’utilisation du site internet accessible à l’adresse <a href='https://www.alpha-buy.com'>https://www.alpha-buy.com</a>, qui propose une plateforme de comparaison d’offres de produits et/ou de services (ci-après « Site »). Toute utilisation du Site suppose que l’utilisateur du Site (ci-après « Utilisateur ») s’engage à respecter les présentes Conditions Générales d’Utilisation. En poursuivant sa navigation sur le Site, l’Utilisateur reconnaît accepter et être contractuellement lié par les termes des présentes Conditions Générales d’Utilisation.
          </Typography>
          <Typography variant='headline'>
            DESCRIPTION
          </Typography>
          <Typography variant='body1'>
            Le Site permet à l’Utilisateur d’effectuer des recherches de produits et/ou services de vendeurs partenaires référencés sur le Site (ci-après « Marchands ») et ainsi de procéder à des comparaisons desdits produits et/ou services.
            L’ordre de présentation des produits et /ou services des Marchands dépend de plusieurs critères et du type de recherche effectué par l’Utilisateur.
            Les résultats affichés sur le Site sont le résultat des flux transmis par les Marchands et ne sont pas exhaustifs eu égard à l’offre de bien et de services disponibles sur le marché.
            En conséquence, Alpha-Buy ne pourra en aucun cas être tenu responsable de l’exactitude ou de l’exhaustivité de ces informations.
          </Typography>
          <Typography variant='headline'>
            ENGAGEMENTS ET GARANTIES DE L’UTILISATEUR
          </Typography>
          <Typography variant='body1'>
            L’utilisation du Site est gratuite et ne nécessite pas d’inscription préalable. Néanmoins toute utilisation du Site à titre commercial est prohibée. L’utilisateur s’engage en effet à utiliser le Site pour un usage exclusivement personnel.
            L’Utilisateur qui souhaite acheter un produit ou un service d’un Marchand référencé devra impérativement se rendre sur le site de ce Marchand pour pouvoir acquérir le produit et/ou service concerné et se soumettra de ce fait aux conditions générales d’utilisation dudit Marchand ainsi qu’à ses conditions générales de vente et sa politique de confidentialité, le cas échéant.

          </Typography>
        </Grid>
      </div>

    );
  }
}


export default LegalMentions;
