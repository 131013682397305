import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { connectRange } from 'react-instantsearch-dom';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

const Range = Slider.Range



class RangeFilter extends Component{
  constructor(props){
    super(props)

    this.state = {}
  }

  scale = limit => Math.round(limit/100 * this.props.max + (1-limit/100)*this.props.min)

  updateRangeLimit = value => {
    const range_inf = this.scale(value[0]);
    const range_sup = this.scale(value[1]);
    this.setState({ range_inf:range_inf, range_sup: range_sup });
  };

  refine = value => {
    this.updateRangeLimit(value);
    this.props.refine({ min: this.state.range_inf, max: this.state.range_sup });
  };

  render(){
    let range_unit = this.props.range_unit ? this.props.range_unit : ''
    return <Grid container direction='row' justify='space-around'
      alignItems='center' style={{ paddingRight: 7 }}>
        <Grid item xs={4}>
          <Typography variant='title' style={{ marginBottom: 5, marginTop:10, color:'#575757', width: '100%' }}>
            {this.props.title}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Grid container direction='column' justify='center' style={{ marginTop:25, marginRight: 15 }}>
            <Grid>
              <Range
                trackStyle={[{ backgroundColor: '#E04C2E' }]}
                handleStyle={[{ borderColor: '#E04C2E' },
                              { borderColor: '#E04C2E' }]}
                onChange={this.updateRangeLimit}
                onAfterChange={this.refine}
                defaultValue={[0, 100]}
                range={[0,100]}
                pushable={true}/>
            </Grid>
            <Grid container direction='row' justify='space-between'>
              <Grid item xs={3}>
                <Typography align='left'>
                  {this.state.range_inf != null?
                    `${this.state.range_inf}${range_unit}`
                    :this.props.min != null?
                      `${this.props.min}${range_unit}`
                      : '-'}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography align='right'>
                  {this.state.range_sup != null?
                    `${this.state.range_sup}${range_unit}`
                    :this.props.max != null?
                      `${this.props.max}${range_unit}`
                      : '-'}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
  }
}

RangeFilter.defaultProps = {
  range_unit: null
}

RangeFilter.propTypes = {
  title: PropTypes.string.isRequired,
  range_unit: PropTypes.string,
  /* all props below are provided by algolia's handling */
  refine: PropTypes.func.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  currentRefinement: PropTypes.shape({
      max: PropTypes.number,
      min: PropTypes.number,
  })
};

export default connectRange(RangeFilter);
