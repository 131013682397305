import React, { Component } from 'react';
import { SearchBox } from 'react-instantsearch-dom';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Link from 'react-router-dom/Link';
import Button from '@material-ui/core/Button';

import logo from 'ressources/icons/logo-big.png';
import './AppBar.css'

const styles = theme => ({
  searchBar: {
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-end',
    }
  },
  logo: {
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-start',
    }
  }
});

class AppBar extends Component {
  render() {
    const { classes } = this.props;
    return (
      <Grid container spacing={8}>
        <Grid container className={classes.logo} item xs={12} sm={6}>
          <Button component={Link} to='' style={{ padding: 0, minHeight: 24 }} >
            <img src={logo} height='48' alt='logo AlphaBuy'/>
          </Button>
        </Grid>
        <Grid className={classes.searchBar} item container xs={12} sm={6} alignItems='center'>
          <SearchBox translations={{ placeholder: 'Rechercher' }} />
        </Grid>
      </Grid>
    );
  }
}


export default withStyles(styles)(AppBar);
